body {
    font-size: 12px;
    --font-size: 12px;
    font-family: var(--ion-font-family);
    --font-family: var(--ion-font-family);
}

.ion-padding {
    padding: 20px !important;
}

.ion-padding-top {
    padding-top: 15px !important;
}

.ion-padding-bottom {
    padding-bottom: 15px !important;
}

.ion-padding-left {
    padding-left: 20px !important;
}

.ion-padding-right {
    padding-right: 20px !important;
}

.ion-padding-x {
    padding: 0px 20px !important;
}

.ion-padding-y {
    padding: 15px 0px !important;
}


.ion-margin {
    margin: 15px 20px !important;
}

.ion-margin-top {
    margin-top: 15px !important;
}

.ion-margin-bottom {
    margin-bottom: 15px !important;
}

.ion-margin-left {
    margin-left: 20px !important;
}

.ion-margin-right {
    margin-right: 20px !important;
}

.ion-margin-x {
    margin: 0px 20px !important;
}

.ion-margin-y {
    margin: 15px 0px !important;
}

.text-primary {
    color: var(--primary)
}

.text-secondary {
    color: var(--secondary)
}

.text-black {
    color: var(--black)
}

.text-grey {
    color: var(--grey)
}

.text-grey2 {
    color: var(--grey2)
}

.text-fail {
    color: var(--fail)
}

.text-center {
    text-align: center;
}

.text-white {
    color: white
}

.bg-primary {
    background-color: var(--grey);
}

.bg-light-primary {
    background-color: var(--light-primary);
}

.bg-white {
    background-color: #fff;
    --background: #fff;
}

.bg-color-primary {
    background-color: var(--primary);
}

// font size
.text-8 {
    font-size: 8px;
}

.text-10 {
    font-size: 10px;
}

.text-12 {
    font-size: 12px;
}

.text-14 {
    font-size: 14px;
}

.text-16 {
    font-size: 16px;
}

.text-18 {
    font-size: 18px;
}

.text-20 {
    font-size: 20px;
}

.text-22 {
    font-size: 22px;
}

.text-26 {
    font-size: 26px;
}

.text-40 {
    font-size: 40px;
}

strong,
b,
.fw-bolder {
    font-family: 'Montserrat-Bold';
    font-weight: 500;
}

hr {
    background: #eaeaea;
}

// end font size
ion-content {
    --background: var(--grey);
    --color: var(--black);
}

ion-modal {
    --color: var(--black);
    color: var(--black);
}

ion-modal.height-auto {
    --height: auto;
}

ion-button {
    letter-spacing: 0;
    font-size: 14px;
    font-family: 'Montserrat-Bold';
    margin: 0px;
    --padding-top: 15px;
    --padding-bottom: 15px;
    --border-radius: 15px;
    --box-shadow: none,
        --background-hover: var(--secondary);
    --background-activated: var(--secondary);
    --background-focused: var(--secondary);
    // --background: transparent linear-gradient(90deg, var(--unnamed-color-09a77e) 0%, #0EC091 100%) 0% 0% no-repeat padding-box;
    --background-color: linear-gradient(90deg, rgba(9, 167, 126, 1) 30%, rgba(16, 204, 154, 1) 100%);
    text-transform: capitalize !important;

}

ion-button.button-disabled {
    --background: var(--grey2);
    --color: #fff
}

ion-checkbox {
    --size: 15px;
}

ion-radio {
    // --border-radius: 50%;
    --inner-border-radius: 50%;
}

ion-radio.md::part(container) {
    width: 16px;
    height: 16px;
}

ion-radio.radio-checked::part(mark) {
    width: 6px;
    height: 6px;
}

ion-input .input-wrapper {
    border: 1px solid #707070;
    border-radius: 10px !important;
    // --border-color: var(--grey2);
    // --border-radius: 10px;
    // --border-width: 1px;
    --padding-bottom: 15px !important;
    --padding-end: 15px !important;
    --padding-start: 15px !important;
    --padding-top: 15px !important;
}

.ion-invalid.ion-dirty .input-wrapper {
    border-color: var(--fail) !important;
}

.input-bottom:has(.error-text) {
    border-top: none !important;
}

ion-input:focus,
ion-input:hover {
    border-color: var(--primary) !important;
}

ion-select {
    border: 1px solid #707070;
    border-radius: 10px;
    --padding-bottom: 15px !important;
    --padding-end: 15px !important;
    --padding-start: 15px !important;
    --padding-top: 15px !important;
}

.ion-select-country {
    min-height: 45px;
}

.d-flex {
    display: flex;
}

.d-inline {
    display: inline;
}

.d-block {
    display: block;
}

.justify-content-between {
    justify-content: space-between;
}

.justify-content-center {
    justify-content: center;
}

.align-item-center {
    align-items: center;
}

.ion-color-grey {
    --ion-color-base: var(--ion-color-grey);
    --ion-color-base-rgb: var(--ion-color-grey-rgb);
    --ion-color-contrast: var(--ion-color-grey-contrast);
    --ion-color-contrast-rgb: var(--ion-color-grey-contrast-rgb);
    --ion-color-shade: var(--ion-color-grey-shade);
    --ion-color-tint: var(--ion-color-grey-tint);
}

.w-100 {
    width: 100%;
}

.w-75 {
    width: 75%;
}

.w-50 {
    width: 50%;
}

.w-25 {
    width: 25%;
}

.text-underline {
    text-decoration: underline;
}

.text-line-through {
    text-decoration: line-through
}

.loading-wrapper {
    background: transparent !important;
    box-shadow: none !important;
}

::-webkit-scrollbar,
*::-webkit-scrollbar {
    display: none;
}

.ion-padding-10 {
    padding: 10px !important;
}

.ion-padding-15 {
    padding: 15px !important;
}

.ion-padding-20 {
    padding: 20px !important;
}

.ion-padding-top-10 {
    padding-top: 10px !important;
}

.ion-padding-top-15 {
    padding-top: 15px !important;
}

.ion-padding-top-20 {
    padding-top: 20px !important;
}

.ion-padding-bottom-15 {
    padding-bottom: 15px !important;
}

.ion-padding-bottom-10 {
    padding-bottom: 10px !important;
}

.ion-padding-bottom-20 {
    padding-bottom: 20px !important;
}

.ion-padding-left-15 {
    padding-left: 15px !important;
}

.ion-padding-left-10 {
    padding-left: 10px !important;
}

.ion-padding-left-20 {
    padding-left: 20px !important;
}

.ion-padding-right-15 {
    padding-right: 15px !important;
}

.ion-padding-right-10 {
    padding-right: 10px !important;
}

.ion-padding-right-20 {
    padding-right: 20px !important;
}

.ion-padding-x-20 {
    padding: 0px 20px !important;
}

.ion-padding-y-20 {
    padding: 20px 0px !important;
}

.ion-padding-x-15 {
    padding: 0px 15px !important;
}

.ion-padding-y-15 {
    padding: 15px 0px !important;
}

.ion-padding-y-10 {
    padding: 10px 0px !important;
}

.ion-margin,
.ion-margin-20 {
    margin: 20px !important;
}

.ion-margin-left-15 {
    margin-left: 15px !important;
}

.ion-margin-left,
.ion-margin-left-20 {
    margin-left: 20px !important;
}

.ion-margin-right-15 {
    margin-right: 15px !important;
}

.ion-margin-right,
.ion-margin-right-20 {
    margin-right: 20px !important;
}

.ion-margin-top-15 {
    margin-top: 15px !important;
}

.ion-margin-top,
.ion-margin-top-20 {
    margin-top: 20px !important;
}

.ion-margin-bottom-15 {
    margin-bottom: 15px !important;
}

.ion-margin-bottom,
.ion-margin-bottom-20 {
    margin-bottom: 20px !important;
}

.ion-margin-x,
.ion-margin-x-20 {
    margin: 0px 20px !important;
}

.ion-margin-y,
.ion-margin-y-20 {
    margin: 20px 0px !important;
}

.ion-margin-x-15 {
    margin: 0px 15px !important;
}

.ion-margin-y-15 {
    margin: 15px 0px !important;
}

.ion-margin-left-10 {
    margin-left: 10px !important;
}

.ion-margin-right-10 {
    margin-right: 10px !important;
}

.ion-margin-top-10 {
    margin-top: 10px !important;
}

.ion-margin-bottom-10 {
    margin-bottom: 10px !important;
}

.ion-margin-x-10 {
    margin: 0px 10px !important;
}

.ion-margin-y-10 {
    margin: 10px 0px !important;
}

ion-infinite-scroll-content {
    min-height: 30px !important;
}

.border-1 {
    border: 1px solid #707070;
}

.border-radius-10 {
    border-radius: 10px;
    --border-radius: 10px;
}

.border-radius-5 {
    border-radius: 5px;
    --border-radius: 5px;
}

.icon {
    width: 20px;
    min-width: 20px;
}

.d-inline-block {
    display: inline-block;
}

.icon-close {
    float: right;
    font-size: 16px;
}

// swiper custom
swiper-container {
    --swiper-pagination-color: var(--primary);
}

.show {
    display: block;
}

.hide {
    display: none !important;
}

.bg-transparent {
    background: transparent;
    --background: transparent !important;
}

.popup-face-verify-success {
    .title {
        font-size: 14px;

        i {
            font-size: 20px;
        }
    }
}

.popup-face-verify-fail {
    .title {
        font-size: 14px;

        i {
            font-size: 20px;
            color: #C73A3A
        }
    }

    .content {
        font-size: 12px;
        line-height: 1.5;
        padding-left: 10px;
        text-align: left;
    }
}

.space-normal {
    white-space: normal !important;
}

.input-promo {
    width: 70%;

    .input-wrapper {
        border: none;
        border-radius: 10px;
        background-color: var(--grey);
        padding-left: 40px;
    }
}

.gocar-toast {
    --background: var(--light-primary);
    --color: var(--primary);
}

.gocar-toast-warning {
    --background: var(--error-light);
    --color: var(--error);
}

.gocar-toast-error {
    --background: var(--fail-light);
    --color: var(--fail);
}

.backdrop-opacity {
    --backdrop-opacity: 0.5 !important;
}

.border-bottom {
    border-bottom: 1px dashed var(--grey2);
}

.border-right {
    border-right: 1px dashed var(--grey2);
}

ion-modal.ios::part(handle) {
    width: 60px !important;
    margin-top: 5px;
    height: 3px !important;
}

ion-modal.md::part(handle) {
    width: 60px !important;
    margin-top: 5px;
    height: 3px !important;
}

// custom calendar
.p-datepicker table td>span {
    border-radius: 50% !important;
    transition: box-shadow 0s !important;
    width: 40px;
    height: 40px;
}

.p-datepicker table td>span.start-date:focus {
    box-shadow: 5px 0px #09A77E;
}

.p-datepicker table td>span.p-highlight {
    color: #ffffff !important;
    background: #09A77E !important;
}

.p-datepicker table td>span.range-date {
    background: #09A77E !important;
    border-radius: 0px !important;
    box-shadow: 5px 0px #09A77E;
}

.p-datepicker table td>span.start-date {
    background: #09A77E !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    box-shadow: 5px 0px #09A77E;
}

.p-datepicker table td>span.end-date {
    background: #09A77E !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.p-datepicker table td:has(.p-highlight):last-child {
    span {
        box-shadow: 0px 0px #9ff3dd;
    }
}

.p-datepicker table td>span.p-highlight::after {
    background: rgba(55, 189, 155, 0.3) !important;
}

.p-datepicker table td>span.p-highlight:focus-visible {
    outline: none !important;
}

.p-datepicker-other-month {
    visibility: hidden;
}

.p-datepicker table td {
    padding: 2px !important;
}

.p-datepicker table td>span.p-highlight:has(span.start) {
    background: #09A77E !important;
    // border-top-left-radius: 50% !important;
    // border-bottom-left-radius: 50% !important;
}

.p-datepicker table td>span.p-highlight:has(span.end) {
    background: #09A77E !important;
    // border-top-right-radius: 50% !important;
    // border-bottom-right-radius: 50% !important;
}

.custom-calendar .p-datepicker table {
    font-size: 12px !important;
}

.custom-calendar button.p-datepicker-month {
    font-size: 14px !important;
    background: #fff;
    font-family: "Montserrat-Bold";
    color: var(--black);
}

.custom-calendar button.p-datepicker-year {
    font-size: 14px !important;
    background: #fff;
    margin-left: 5px;
    font-family: "Montserrat-Bold";
    color: var(--black);
}

.custom-calendar .p-datepicker-prev {
    font-size: 14px !important;
    background: #fff;
    color: var(--black);
    padding-left: 10px;
}

.custom-calendar .p-datepicker-prev:disabled {
    color: var(--grey2) !important;
}

.custom-calendar .p-datepicker-next {
    font-size: 14px !important;
    background: #fff;
    color: var(--black);
    padding-right: 10px;
}

.custom-calendar .p-datepicker-next:disabled {
    color: var(--grey2) !important;
}

.custom-calendar .p-hidden-accessible {
    display: none;
}

.custom-calendar .p-disabled {
    color: var(--grey2);
}

.p-datepicker-calendar-container {
    margin-top: 20px;
}

.p-monthpicker .p-monthpicker-month {
    padding-top: 15px;
}

.p-yearpicker .p-yearpicker-year {
    padding-top: 15px;
}

// end custom calendar

ion-searchbar {
    padding: 0 !important;

    .searchbar-input-container {
        border: 1px solid #707070;
        border-radius: 10px;
        height: 40px !important;

        .searchbar-input {
            background-color: #fff;
            font-size: 12px !important;
        }

        .searchbar-search-icon {
            width: 20px !important;
        }
    }
}

// class rm top spacing between banner and content
.rm-spacing {
    margin-top: -4px;
}

.popup-fail {
    .title {
        color: var(--fail);
    }
}

.overflow-instruction {
    background-color: #ECFFEA;
    padding: 10px 12px;
    color: #3B8069;
    border-radius: 5%;
}