// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-font-family: 'Montserrat-Regular';
  /** MAIN COLOR **/
  --primary: #09A77E;
  --secondary: #00563F;
  --light-primary: #ECFFEA;
  --orange: #F7A326;
  --error: #E26A03;
  --error-light: #FFEBD8;
  --fail: #C61B32;
  --fail-light: #FFE8E5;
  --black: #4E4E4E; // use for text
  --grey: #F7F7F7; // use for background
  --grey2: #AFAFAF;
  --inactive: #D9D8D8;

  /** primary **/
  --ion-color-primary: var(--primary);
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: var(--primary);
  --ion-color-primary-tint: var(--primary);

  /** secondary **/
  --ion-color-secondary: var(--secondary);
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: var(--secondary);
  --ion-color-secondary-tint: var(--secondary);

  // /** tertiary **/
  // --ion-color-tertiary: #5260ff;
  // --ion-color-tertiary-rgb: 82, 96, 255;
  // --ion-color-tertiary-contrast: #ffffff;
  // --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  // --ion-color-tertiary-shade: #4854e0;
  // --ion-color-tertiary-tint: #6370ff;

  // /** success **/
  // --ion-color-success: #2dd36f;
  // --ion-color-success-rgb: 45, 211, 111;
  // --ion-color-success-contrast: #ffffff;
  // --ion-color-success-contrast-rgb: 255, 255, 255;
  // --ion-color-success-shade: #28ba62;
  // --ion-color-success-tint: #42d77d;

  // /** warning **/
  // --ion-color-warning: #ffc409;
  // --ion-color-warning-rgb: 255, 196, 9;
  // --ion-color-warning-contrast: #000000;
  // --ion-color-warning-contrast-rgb: 0, 0, 0;
  // --ion-color-warning-shade: #e0ac08;
  // --ion-color-warning-tint: #ffca22;

  // /** danger **/
  // --ion-color-danger: #eb445a;
  // --ion-color-danger-rgb: 235, 68, 90;
  // --ion-color-danger-contrast: #ffffff;
  // --ion-color-danger-contrast-rgb: 255, 255, 255;
  // --ion-color-danger-shade: #cf3c4f;
  // --ion-color-danger-tint: #ed576b;

  // /** dark **/
  // --ion-color-dark: #222428;
  // --ion-color-dark-rgb: 34, 36, 40;
  // --ion-color-dark-contrast: #ffffff;
  // --ion-color-dark-contrast-rgb: 255, 255, 255;
  // --ion-color-dark-shade: #1e2023;
  // --ion-color-dark-tint: #383a3e;

  // /** medium **/
  // --ion-color-medium: #92949c;
  // --ion-color-medium-rgb: 146, 148, 156;
  // --ion-color-medium-contrast: #ffffff;
  // --ion-color-medium-contrast-rgb: 255, 255, 255;
  // --ion-color-medium-shade: #808289;
  // --ion-color-medium-tint: #9d9fa6;

  // /** light **/
  // --ion-color-light: #f4f5f8;
  // --ion-color-light-rgb: 244, 245, 248;
  // --ion-color-light-contrast: #000000;
  // --ion-color-light-contrast-rgb: 0, 0, 0;
  // --ion-color-light-shade: #d7d8da;
  // --ion-color-light-tint: #f5f6f9;

  // /** grey **/
  --ion-color-grey: var(--grey2);
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-grey-shade: var(--grey2);
  --ion-color-grey-tint: var(--grey2);

  // others
  --border-radius: 10px;
  --swiper-theme-color: var(--primary);
  --swiper-navigation-size: 22px
}