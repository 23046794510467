@charset "UTF-8";
/* CSS Document */

body{
    font-size: 12px;
    line-height: 1.3;
    font-family: 'Montserrat', sans-serif;
    color:#4E4E4E;
}

h1{
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: 700;
    color:#00563F;
}

h2{
    font-size: 14px;
    font-weight: 700;
    color: #00563F;
}

p{
    margin: 5px 0;
}

ul,ol{
    margin-bottom: 0;

}

.home-title-pt{
    padding-top: 30px;
}


li{
    padding-bottom: 10px;
}

.bg-green-gradient{
    background: rgb(9,167,126);
background: linear-gradient(90deg, rgba(9,167,126,1) 30%, rgba(16,204,154,1) 100%);
}

.slide-item-service{
    margin-right: 30px;
}

.slide-item-service img{
    width: 18px;
    margin-right: 10px;
    vertical-align: top;
}

.slide-item-promo img{
    width: 152px;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.1215686275);

}

.user-login{
    width: 30px;
    vertical-align: bottom;
}

.shadow-inset{
    -webkit-box-shadow: inset 0px 10px 13px -11px rgba(0,0,0,0.2);
-moz-box-shadow: inset 0px 10px 13px -11px rgba(0,0,0,0.2);
box-shadow: inset 0px 10px 13px -11px rgba(0,0,0,0.2);
}

hr{
    /* border-top: 1px solid #d4d4d4; */
    /* margin-top: 15px; */
    /* margin-bottom: 0; */

}



/*Color Palette*/
.bg-light-green{
    background-color:#ECFFEA ;
}

.bg-orange{
    background-color: #F7A326;
}

.bg-highlight{
    background: rgb(242,97,14);
background: linear-gradient(180deg, rgba(242,97,14,1) 0%, rgba(255,137,0,1) 100%);
}

/* button */
.btn{
   font-size: 12px;
   text-align: center;
   border-radius: 10px;
   padding:15px 0;
   width: 100%;
   text-transform: none;
   font-weight: 700;
   margin-bottom: 15px;

}

.btn:active{
    background-color:#00563F ;
}

.disabled{
    background-color: #AFAFAF;
    color:#fff;
}

.btn-primary{
    background: rgb(9,167,126);
    background: linear-gradient(90deg, rgba(9,167,126,1) 0%, rgba(14,192,145,1) 100%);
    color:#fff;
}

.btn-outline{
    background-color: #fff;
    border: 1px solid #00563F;
    color: #00563F;
}

.group-cards{
    padding-left: 0;
}

.group-cards li{
    display: inline-block;
    margin-right: 15px;
}

.card{
    -webkit-box-shadow: 0px 3px 12px 0px rgba(0,0,0,.1);
    -moz-box-shadow: 0px 3px 12px 0px rgba(0,0,0,.1);
    box-shadow: 0px 3px 12px 0px rgba(0,0,0,.1); 
    max-width: 185px ;
    border-radius: 10px;
    padding: 10px;
}
.card img{
    margin: 0 auto;
    display: block;
}



.distance{
    color: #00563F;
    font-weight: 700;
    padding: 5px 10px;
    font-size: 10px;
    border-radius: 5px;
    margin-top: 15px;
    margin-bottom: 10px;
    display:inline-flex
    
}

.page-header{
    padding: 15px 0;
}

.page-header h2{
    display: inline;
    margin-left: 10px;
}

.bg-popup {
    background-color: rgba(0,0,0,.7);
}

.dummy-height{
    padding-top:30px
}

.popup-bottom h2{
    color:#333333;
    margin: 0;
    margin-bottom: 15px;
}

.popup-card{
    background-color: #fff;
    padding: 20px;
    border-radius: 10px 10px 0px 0px;
}

.popup-close{
    text-align: right;
    font-size: 24px;
    color: #000;
}